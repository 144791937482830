import styled from '@emotion/styled'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  siteName?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
  siteName,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Wrapper>
        <Top>
          {siteName ? <Heading>{siteName}</Heading> : null}
          {title ? <Title>{title}</Title> : null}
        </Top>

        {categories.length > 1 ? (
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        ) : null}

        <Mosaic
          options={{
            columnWidth: 1,
            percentPosition: true,
          }}
        >
          {uniqBy(categories, 'title').map((list, index) => {
            const category = list.title
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()
            if (!list.thumbnails) {
              return null
            }
            return (
              <React.Fragment key={index}>
                <Category>{list.title}</Category>
                {list.thumbnails.map((item, index) => {
                  counter = counter + 1
                  return (
                    <Thumb
                      className={
                        filteredCategory === category ||
                        filteredCategory === 'all'
                          ? 'visible'
                          : undefined
                      }
                      style={
                        item.width && item.height
                          ? { aspectRatio: '' + item.width / item.height }
                          : undefined
                      }
                      data-counter={counter}
                      key={index}
                      onClick={(e) => {
                        if (!modalGalleryStatus) {
                          setLocked(true)
                          setModalGalleryStatus(true)
                        } else {
                          setLocked(false)
                          setModalGalleryStatus(false)
                        }
                        const realClickedSlide =
                          Number(e.currentTarget.getAttribute('data-counter')) -
                          1
                        setClickedSlide(realClickedSlide)
                        setTimeout(() => {
                          setClickedSlide(null)
                        }, 100)
                      }}
                    >
                      <LazyLoadComponent>
                        <Image {...item} />
                      </LazyLoadComponent>
                    </Thumb>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Mosaic>

        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 10rem 8.938vw;

  @media (max-width: 1199px) {
    padding: 10rem 1.5625rem 5.625rem;
  }
`

const Wrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
`

const Top = styled.div`
  max-width: 41.875rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    max-width: 21rem;
  }
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  line-height: 4.6875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Title = styled.h2`
  margin-top: 1.125rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
`

const Filters = styled(FlexBox)`
  margin-top: 4.5rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Mosaic = styled(Masonry)<MasonryProps>`
  margin: 0 -1.875rem 0 0;

  @media (max-width: 1199px) {
    margin: 0 -1.25rem 0 0;
  }
`

const Category = styled.div`
  position: relative;
  display: none;
  width: 100%;
  margin-top: 3.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
  }
`

const Thumb = styled.div`
  position: relative;
  display: none;
  width: calc(24.9% - 1.875rem);
  max-height: 22.5rem;
  margin: 1.875rem 1.875rem 0 0;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 1440px) {
    width: calc(33% - 1.875rem);
  }

  @media (max-width: 1199px) {
    width: calc(33% - 1.25rem);
    margin: 1.25rem 1.25rem 0 0;
  }

  @media (max-width: 992px) {
    width: calc(49.9% - 1.25rem);
  }

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
